
import { defineAsyncComponent, defineComponent } from "vue";

export default defineComponent({
  name: "TableItemSkeleton",
  components: {
    CardButton: defineAsyncComponent(() => import('@/components/CardButton.vue')),
  },
  props: {
    ratio: {type: Number, default: 10},
    logo: {type: String, default: ''},
    fields: {type: Array, default: []},
    actions: {type: Array, default: []},
  }
});
